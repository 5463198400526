<template>
    <div id="wrapper_auth">
        <div>
            <v-btn rounded class="elevation-12" to="/">
                <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>
        </div>
        <div>
            <v-card class="elevation-12 uk-padding-small">
                <v-card-text>
                    <router-view name="router_wrapper_auth"></router-view>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Auth"
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/auth/index.scss';
</style>
